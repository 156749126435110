/**
 * Benefit post Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannertestimonial from "../components/bannertestimonial"
import Bannerwithcta from "../components/bannerwithcta"
import Blockfeaturesvertical from "../components/blockfeaturesvertical"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/templates/benefitpost.scss"

/* Declaration function */
const BenefitPostTemplate = ({ data }) => {

  //Const data page
  const dataPage = data.wpBenefit
  var classPage = dataPage.slug
  var classImg = ""

  if(classPage === "referral-program") {
    classImg = "no-shadow"
  }

  //Const data ACF page
  const dataAcf = data.wpBenefit.acfPostBenefit

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroBenefit.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroBenefit})
  ))

  //Array for features vertical
  let arrFeatures = []
  dataAcf.repeaterFeaturesBenefit.map( (feature, index )  => (
    arrFeatures.push({idFeature: "feature-benefit-"+index, indexFeature: index, titleFeature: feature.titleFeatureBenefit, descFeature:feature.descriptionFeatureBenefit, visualFeature: feature.visualFeatureBenefit.localFile.childImageSharp.gatsbyImageData})
  ))

 //Data Testimonial
  var extLinkTestimonial = false
  let arrTestimonial = []
  if(dataAcf.ctaLinkTestimonialBenefit !== null) {
    const dataTestimonial = dataAcf.linkTestimonialBenefit
    arrTestimonial.push({
      author: dataTestimonial[0].acfPostTestimonials.authorTestimonial,
      content: dataTestimonial[0].acfPostTestimonials.contentTestimonial,
      title: dataTestimonial[0].title,
      video: dataTestimonial[0].acfPostTestimonials.videoLinkTestimonial
    })
    var splitUrlCtaTestimonial= dataAcf.ctaLinkTestimonialBenefit.split("/")
    var sourceUrlCtaTestimonial = splitUrlCtaTestimonial[0]

    if(sourceUrlCtaTestimonial !== "") {
      extLinkTestimonial = true
    }
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "demo"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero */}
      <section className={`section-hero-benefit top-section ${classPage}`}>
        <Herotype1 keyHero={dataPage.title} h1title={dataAcf.titleHeroBenefit} arrIntro={arrTextHero} />
      </section>

      {/* Features benefit */}
      <section className="section-features-benefit">
        <div className="wrap-features wrap-maincontent">
          <Titledecorative title={dataAcf.titleFeaturesBenefit} />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textFeaturesBenefit}} />
          </div>
        </div>
        <Blockfeaturesvertical arrFeaturesVertical={arrFeatures} classImg={classImg}/>
      </section>

      {/* Testimonial benefit */}
      {arrTestimonial.length > 0 && (
        <section className="section-testimonial-product">
          <Bannertestimonial
            titleBannerTestimonial={dataAcf.titleTestimonialProduct}
            textBannerTestimonial={dataAcf.textTestimonialProduct}
            ctaLabelBannerTestimonial={dataAcf.ctaLabelTestimonialProduct}
            ctaLinkBannerTestimonial={dataAcf.ctaLinkTestimonialProduct}
            extLinkBannerTestimonial ={extLinkTestimonial}
            arrContentTestimonial={arrTestimonial}
          />
        </section>
      )}

      {/* Banner CTA Benefit */}
      <Bannerwithcta
        classBanner=""
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default BenefitPostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!) {
    wpBenefit(databaseId: {eq: $id}) {
      title
      slug
      acfPostBenefit {
        ctaLabelTestimonialBenefit
        ctaLinkTestimonialBenefit
        linkTestimonialBenefit {
          ... on WpTestimonial {
            acfPostTestimonials {
              authorTestimonial
              contentTestimonial
              videoLinkTestimonial
            }
            title
          }
        }
        repeaterFeaturesBenefit {
          descriptionFeatureBenefit
          titleFeatureBenefit
          visualFeatureBenefit {
            localFile {
              childImageSharp {
                gatsbyImageData (layout: CONSTRAINED, width: 900, aspectRatio: 1.5)
              }
            }
          }
        }
        repeaterHeroBenefit {
          introHeroBenefit
        }
        textFeaturesBenefit
        textTestimonialBenefit
        titleFeaturesBenefit
        titleHeroBenefit
        titleTestimonialBenefit
      }
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
